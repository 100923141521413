@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

$base-font: 'Lato', sans-serif;
$hfont: 'Karla', sans-serif;
$primary-color: #ff3399;
$darktext-color: #a0a7bb;
$background-color: #0a192f;
$disable-color: #c9d3f2;
$bg_card_color: #293a52;
$background_even: #5c6e85;
$background_odd: #293a52;

body {
  margin: 0;
  font-family: $base-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
  overflow: hidden;
}

h1,
h2 {
  font-family: $hfont !important;
}

#login_container {
  height: 100vh;
  width: 100vw;
  margin: 0;
  #login_illustration {
    width: 80%;
    height: auto;
    display: block;
    margin: auto;
  }
}

.darktext {
  color: $darktext-color;
}

table {
  button {
    color: $disable-color;
  }
  thead {
    .MuiTableRow-root {
      border-bottom: none !important;
    }
    tr th {
      color: $darktext-color !important;
      background: $background-color !important;

      &:hover {
        background: #0a192fb3 !important;
        cursor: pointer;
      }
      &:first-child {
        &:hover {
          background: $background-color !important;
          cursor: auto;
        }
      }
    }
  }
  tbody {
    button {
      color: $disable-color !important;
    }
    tr {
      &:nth-child(odd) {
        background: $bg_card_color !important;
      }
      &:nth-child(even) {
        background: $background_even !important;
      }
    }
  }
}

.myDataTable {
  h6.MuiTypography-root {
    color: $disable-color;
  }
  .icon {
    position: relative;
    top: 2px;
  }
  .icon.folder {
    top: 4px;
  }
  .smellIcon {
    width: 16px;
    position: relative;
    top: 3px;
    padding-right: 4px;
  }
  .MuiToolbar-root,
  tfoot,
  .MuiTableCell-footer {
    padding: 0;
    background: $background-color;
    color: $darktext-color;
  }
  .MuiButtonBase-root {
    color: inherit;
  }
  .MuiTableCell-head {
    .MuiButtonBase-root {
      padding: 6px 0;
      justify-content: start;
    }
  }
  .myExpandableRow {
    background: $background-color;
    color: $darktext-color;
  }
  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
    .MuiTableSortLabel-icon {
    color: $darktext-color;
  }
  .readmore_odd,
  .readmore_even {
    padding: 10px;
    color: $disable-color;
  }
  .readmore_odd {
    background: $background_odd;
  }
  .readmore_even {
    background: $background_even;
  }

  .moreInfo {
    h5 {
      margin: 8px 0 32px 0;
    }
  }
}

.tss-ynxllk-MUIDataTableFilter-root {
  background: $bg_card_color !important;
  padding: 0 !important;
  width: 360px;
  p {
    color: $darktext-color;
  }
}

.tss-bd2lw8-MUIDataTableHeadCell-sortActive,
.MuiSvgIcon-root[data-testid='CloseIcon'] {
  color: $darktext-color !important;
}

.css-ubpweo-MuiPaper-root-MuiPopover-paper {
  padding: 20px;
  background: $bg_card_color !important;
  color: $darktext-color !important;
  .tss-1nmvwsz-MUIDataTableViewCol-title,
  .tss-1sf0hhi-MUIDataTableViewCol-label {
    color: $darktext-color;
  }
}

.tss-1vsygk-MUIDataTableFilterList-root {
  padding: 0;
  margin: 0 !important;
  background: $background-color;
  .MuiChip-label {
    color: $darktext-color;
  }
}

.cardoverview {
  height: 264px;
  position: relative;
  .values {
    width: calc(100% - 32px);
    position: absolute;
    bottom: 24px;
  }
}

g.needle {
  path,
  circle {
    fill: $disable-color;
  }
}

.gauge-text {
  display: block;
  margin: auto;
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: normal;
  }
  .percent {
    font-size: 1.8rem;
  }
  .value {
    font-size: 1.4rem;
  }
}

.center {
  text-align: center;
  b {
    color: $disable-color;
    font-size: 2rem;
  }
}

.left {
  float: left;
}
.right {
  float: right;
}
/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

.subheadernav {
  margin-right: auto;
  .title {
    span {
      position: relative;
      left: -18px;
    }
    .iconexp {
      position: relative;
      top: 12px;
    }
  }
}

nav.clean {
  background: none !important;
  p {
    color: $disable-color;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $darktext-color;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $darktext-color;
  border-radius: 10px;
}
